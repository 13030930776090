import {createApp, markRaw} from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import './assets/tailwind.css'
import Vue3EasyDataTable from 'vue3-easy-data-table'
import 'vue3-easy-data-table/dist/style.css';
import {createPinia} from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import VueProgressBar from "@aacassandra/vue3-progressbar";
import VueApexCharts from "vue3-apexcharts";

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
pinia.use(({ store }) => {
    store.router = markRaw(router)
})

const options = {
    color: 'rgb(165 180 252)',
    failedColor: "#874b4b",
    thickness: "5px",
    transition: {
        speed: "0.2s",
        opacity: "0.10s",
        termination: 300,
    },
    autoRevert: true,
    location: 'top',
    inverse: false,
    position: 'relative'
};

createApp(App)
    .use(router)
    .use(VueProgressBar, options)
    .use(pinia)
    .use(VueApexCharts)
    .component('EasyDataTable', Vue3EasyDataTable)
    .mount('#app')
