import {createRouter, createWebHistory} from 'vue-router'
import Reporter from "@/views/ReporterIsDead.vue";
import ServerCheck from "@/views/ServerCheck.vue";
const routes = [
    {
        path: '/',
        name: 'Deas',
        component: Reporter
    },
    {
        path: '/server-check',
        name: 'Server check',
        component: ServerCheck
    },
    ]

    const router = createRouter({
        history: createWebHistory(process.env.BASE_URL),
        routes
    })

export default router
